<template>
  <section id="article">
    <v-theme-provider dark>
      <v-img
          :height="$vuetify.breakpoint.mdAndUp ? 350 : 225"
          :gradient="gradient"
          :src="src"
          color="rgba(24, 61, 114, .9)"
          flat
          max-width="100%"
          tile
      >
        <v-row
            align="center"
            class="ma-0 fill-height text-center"
            justify="center"
        >
          <v-col cols="12">
            <base-heading
                :title="article.title ? article.title.replace(/\n/g, '<br>') : ''"
                space="2"
                size="display-1"
                mobile-size="text-h6"
                padded
                align="center"
            />
            <base-divider
                color="primary"
                dense
                class="mx-auto"
            />
          </v-col>
        </v-row>
      </v-img>
    </v-theme-provider>
    <v-container :class="$vuetify.breakpoint.mdAndUp ? 'column my-10 px-8' : 'column my-4 px-8'">
      <div class="d-flex justify-center mb-8" v-if="article.logo">
        <v-img
            max-width="200"
            max-height="100"
            :src="`https://strapi.ethosevaluate.net${article.logo}`"
            style="opacity: 1"
        />
      </div>
      <div v-html="article.story"></div>
    </v-container>
    <SectionPosts/>
    <SectionRequestDemo/>
    <SectionInfo/>
  </section>
</template>

<script>
import View from '@/views/View';
import axios from 'axios';
import SectionInfo from '@/views/sections/Info';
import SectionPosts from '@/views/sections/Posts';
import SectionRequestDemo from '@/views/sections/RequestDemo';

export default {
  name: 'Article',

  metaInfo() {
    return {
      title: this.article.title,
      meta: this.articleMeta,
    };
  },

  extends: View,

  components: {
    SectionInfo,
    SectionPosts,
    SectionRequestDemo,
  },

  props: {
    id: {
      type: String,
      default: 'article',
    },
    slug: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    isLoading: false,
    article: {},
  }),
  computed: {
    articleMeta() {
      const meta = [];
      meta.push({name: 'description', content: this.article.lead});
      meta.push({property: 'og:type', content: 'article'});
      meta.push({
        property: 'og:url',
        content: `https://www.evaluate.se/${this.$i18n.locale}/article/${this.article.slug}`
      });
      meta.push({property: 'og:site_name', content: 'Evaluate'});
      meta.push({property: 'og:title', content: `Evaluate – ${this.article.title}`});
      meta.push({property: 'og:description', content: this.article.lead});
      meta.push({property: 'og:image', content: `https://strapi.ethosevaluate.net${this.article.image}`});
      meta.push({property: 'og:locale', content: this.article.locale || 'en_GB'});
      if (this.article.meta) {
        Object.keys(this.article.meta).forEach(key => {
          meta.push({
            property: key,
            content: this.article.meta[key],
          });
        });
      }
      return meta;
    },
    gradient() {
      // const color = `${this.$vuetify.theme.themes.light.primary}CC`
      // const overlay = RGBAtoCSS(HexToRGBA(color))
      // return `to right, ${overlay}, ${overlay}`
      return `to left, rgba(0, 0, 0, .8), rgba(24, 61, 114, .9)`;
    },
    src() {
      return this.article.image ? `https://strapi.ethosevaluate.net${this.article.image}` : this.$route.meta.src;
    },
  },
  watch: {
    slug: {
      async handler(v) {
        if (v) {
          this.isLoading = true;
          let id;
          if (v.includes('-')) {
            const parts = v.split('-');
            if (!isNaN(parts[0])) {
              id = Number(parts[0]);
            }
          }
          let articleData;
          try {
            if (id) {
              const res = await axios.get(`https://strapi.ethosevaluate.net/api/posts/${id}?populate[0]=Image&populate[1]=Logo`);
              articleData = res.data?.data?.attributes;
            } else {
              const res = await axios.get(`https://strapi.ethosevaluate.net/api/posts?locale=${this.$i18n.locale}&filters[Slug]=${v}&populate[0]=Image&populate[1]=Logo`);
              articleData = res.data?.data[0]?.attributes;
            }
          } catch (e) {
            console.error(e);
          }
          if (articleData) {
            this.article = {
              title: articleData.Title,
              locale: articleData.Locale,
              slug: articleData.Slug,
              date: articleData.Date,
              image: articleData.Image.data?.attributes?.formats?.large?.url,
              logo: articleData.Logo.data?.attributes?.formats?.medium?.url || articleData.Logo.data?.attributes?.url,
              story: this.parseBlocks(articleData.Story),
              lead: articleData.Lead,
            };
            this.$nextTick(() => {
              setTimeout(() => {
                window.prerenderReady = true;
              }, 500);
              this.injectLD();
            });
          }
          this.isLoading = false;
        }
      },
      immediate: true,
    }
  },
  methods: {
    parseBlocks(blocks) {
      const htmlParts = [];
      if (Array.isArray(blocks)) {
        for (const block of blocks) {
          if (block.type === 'paragraph' && Array.isArray(block.children)) {
            for (const child of block.children) {
              if (child.type === 'text') {
              htmlParts.push(child.bold ? `<p><strong>${child.text}</strong></p>` : `<p>${child.text}</p>`);
              } else if (child.type === 'link') {
                htmlParts.push(child.bold ? `<a href="${child.url}" target="_new"><strong>${Array.isArray(child.children) ? child.children[0]?.text : child.url}</strong></a>` : `<a href="${child.url}" target="_new">${Array.isArray(child.children) ? child.children[0]?.text : child.url}</a>`);
              }
            }
          }
          if (block.type === 'list' && Array.isArray(block.children)) {
            htmlParts.push(block.format === 'unordered' ? '<ul style="margin-bottom: 12px">' : '<ol style="margin-bottom: 12px">');
            for (const child of block.children) {
              if (child.type === 'list-item') {
                htmlParts.push('<li>');
                if (Array.isArray(child.children)) {
                  for (const subChild of child.children) {
                    htmlParts.push(subChild.bold ? `<strong>${subChild.text}</strong>` : subChild.text);
                  }
                }
                htmlParts.push('</li>');
              }
            }
            htmlParts.push(block.format === 'unordered' ? '</ul>' : '</ol>');
          }
        }
      }
      return htmlParts.join('');
    },
    injectLD() {
      const ld = {
        '@context': 'http://schema.org/',
        '@type': 'Article',
        'headline': this.article.title,
        'author': this.article.author || 'Ethos Evaluate AB',
        'genre': 'Supply Chain Sustainability',
        'description': this.article.lead,
        'articleBody': this.htmlToText(this.article.story),
        'publisher': {
          '@type': 'Organization',
          'name': 'Ethos Evaluate AB',
          'logo': 'https://cdn.ethosevaluate.net/core/assets/logo_blue.png',
        },
        'url': window.location.href,
      };
      if (this.article.image?.url) {
        ld.image = `https://strapi.ethosevaluate.net${this.article.image}`;
      }
      if (this.article.published_at) {
        ld.datePublished = this.article.published_at;
      }
      if (this.article.createdAt) {
        ld.dateCreated = this.article.createdAt;
      }
      if (this.article.updatedAt) {
        ld.dateModified = this.article.updatedAt;
      }
      let el = window.document.getElementById('json-ld');
      if (el) {
        el.innerText = JSON.stringify(ld);
      }
    },
    htmlToText(html) {
      let text = html;
      text = text.replace(/\n/gi, '');
      text = text.replace(/<style([\s\S]*?)<\/style>/gi, '');
      text = text.replace(/<script([\s\S]*?)<\/script>/gi, '');
      text = text.replace(/<a.*?href="(.*?)[?"].*?>(.*?)<\/a.*?>/gi, ' $2 $1 ');
      text = text.replace(/<\/div>/gi, '\n\n');
      text = text.replace(/<\/li>/gi, '\n');
      text = text.replace(/<li.*?>/gi, '  *  ');
      text = text.replace(/<\/ul>/gi, '\n\n');
      text = text.replace(/<\/p>/gi, '\n\n');
      text = text.replace(/<br\s*[/]?>/gi, '\n');
      text = text.replace(/<[^>]+>/gi, '');
      text = text.replace(/^\s*/gim, '');
      text = text.replace(/ ,/gi, ',');
      text = text.replace(/ +/gi, ' ');
      text = text.replace(/\n+/gi, '\n\n');
      return text;
    }
  }
};
</script>

<style>
strong {
  font-weight: 600;
}

.article-image-right {
  float: right;
  padding: .5rem 0 .5rem 1rem;
  max-width: 33%;
}

.article-image-left {
  float: left;
  padding: .5rem 1rem .5rem 0;
  max-width: 33%;
  font-size: 75%;
}

.article-image-left img, .article-image-right img {
  width: 100%;
}

.column {
  max-width: 700px;
}
</style>
